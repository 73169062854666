import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiRelatorio = {
    relatorioAmbiente: async (unidadeId, fusoData) => {
        // const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/relatorio/ambiente?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioCompressor: async (unidadeId, fusoData) => {
        // const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/relatorio/compressor?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioRegime: async (unidadeId, fusoData, periodo) => {
        // const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/relatorio/regime?unidadeId=${unidadeId}&periodo=${periodo}`;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },


}

export default apiRelatorio; 