import { FaHome } from 'react-icons/fa';
import {
	TbCircleLetterR,
	TbCircleLetterC,
	TbCircleLetterU,
	TbCircleLetterG,
	TbCircleLetterE,
	TbCircleLetterM,
	TbCircleLetterT,
	TbCircleLetterA,
	TbCircleLetterB,
	TbCircleLetterV,
	TbCircleLetterS,
} from 'react-icons/tb';

import { BiSolidDashboard } from 'react-icons/bi';

import { VscGraph } from 'react-icons/vsc';

import { MdOutlineEnergySavingsLeaf } from 'react-icons/md';
import { IoAirplane, IoDocumentText } from 'react-icons/io5';

import { TbUserPlus } from 'react-icons/tb';
import { ArrowLeft } from 'lucide-react';

const sizeIcons = 'h-5 w-5 lg:h-6 lg:w-6';

export const publicPages = ['/login', '/recuperar-senha', '/reset-password'];

export const colorsPallete = [
	/* Cores com classe em globals.css */
	{
		class: 'amber500',
		tw: 'amber-500',
		color: '#f59e0b',
		borderClass: 'LBamber500',
	},
	{
		class: 'red700',
		tw: 'red-700',
		color: '#b91c1c',
		borderClass: 'LBred700',
	},
	{
		class: 'emerald600',
		tw: 'emerald-600',
		color: '#059669',
		borderClass: 'LBemerald600',
	},
	{
		class: 'orange600',
		tw: 'orange-600',
		color: '#ea580c',
		borderClass: 'LBorange600',
	},
	{
		class: 'pink600',
		tw: 'pink-600',
		color: '#86198f',
		borderClass: 'LBpink600',
	},
	{
		class: 'fuchsia800',
		tw: 'fuchsia-800',
		color: '#db2777',
		borderClass: 'LBfuchsia800',
	},
	/* {
		id: 7,
		class: 'amber500',
		tw: 'amber-500',
		color: '#f59e0b',
		borderClass: 'LBamber500',
	},
	{
		id: 8,
		class: 'red700',
		tw: 'red-700',
		color: '#b91c1c',
		borderClass: 'LBred700',
	},
	{
		id: 9,
		class: 'emerald600',
		tw: 'emerald-600',
		color: '#059669',
		borderClass: 'LBemerald600',
	},
	{
		id: 10,
		class: 'orange600',
		tw: 'orange-600',
		color: '#ea580c',
		borderClass: 'LBorange600',
	},
	{
		id: 11,
		class: 'pink600',
		tw: 'pink-600',
		color: '#86198f',
		borderClass: 'LBpink600',
	},
	{
		id: 12,
		class: 'fuchsia800',
		tw: 'fuchsia-800',
		color: '#db2777',
		borderClass: 'LBfuchsia800',
	}, */
];

export const SidebarData = [
	{
		_categoryId: 0,
		category: 'Unidade',
		items: [
			{
				title: 'Resumo',
				href: '/resumo-unidade',
				icon: <FaHome className={sizeIcons} />,
				submenu: true,
				submenuItems: [
					{
						title: 'Dashboard',
						href: '/item1',
					},
				],
			},
		],
	},
	{
		_categoryId: 1,
		category: 'Monitoramento',
		items: [
			{
				title: 'Geral',
				href: '/monitoramento/geral',
				icon: <BiSolidDashboard className={sizeIcons} />,
				submenu: true,
				submenuItems: [
					{
						title: 'Dashboard',
						href: '/item1',
					},
				],
			},
			{
				title: 'Energia',
				href: '/monitoramento/energia',
				icon: (
					<MdOutlineEnergySavingsLeaf
						className={sizeIcons}
					/>
				),
				submenu: false,
			},
			{
				title: 'Cargas Termicas',
				href: '/monitoramento/cargas-termicas',
				icon: <VscGraph className={sizeIcons} />,
				submenu: false,
			},
		],
	},
	// {
	// 	_categoryId: 2,
	// 	category: 'Equipamentos',
	// 	items: [
	// 		{
	// 			title: 'Unidade',
	// 			href: '/equipamentos/unidade',
	// 			icon: <TbCircleLetterU className={sizeIcons} />,
	// 			submenu: false,
	// 		},
	// 		{
	// 			title: 'Regime',
	// 			href: '/equipamentos/regime',
	// 			icon: <TbCircleLetterR className={sizeIcons} />,
	// 			submenu: false,
	// 		},
	// 		{
	// 			title: 'Compressor',
	// 			href: '/equipamentos/compressor',
	// 			icon: <TbCircleLetterC className={sizeIcons} />,
	// 			submenu: false,
	// 		},
	// 		{
	// 			title: 'Condensador',
	// 			href: '/equipamentos/condensador',
	// 			icon: <TbCircleLetterC className={sizeIcons} />,
	// 			submenu: false,
	// 		},
	// 	],
	// },
	{
		_categoryId: 3,
		category: 'Relatorios',
		items: [
			{
				title: 'Ambientes',
				href: '/relatorio/ambiente',
				icon: <IoDocumentText  className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Compressores',
				href: '/relatorio/compressor',
				icon: <IoDocumentText  className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Regimes',
				href: '/relatorio/regime',
				icon: <IoDocumentText  className={sizeIcons} />,
				submenu: false,
			},
			// {
			// 	title: 'Energia',
			// 	href: '/energia',
			// 	icon: (
			// 		<MdOutlineEnergySavingsLeaf
			// 			className={sizeIcons}
			// 		/>
			// 	),
			// 	submenu: false,
			// },
			// {
			// 	title: 'Alarmes',
			// 	href: '/Alarmes',
			// 	icon: <RiAlarmWarningFill className={sizeIcons} />,
			// 	submenu: false,
			// },
		],
	},
	{
		_categoryId: 4,
		category: 'Simulação',
		items: [
			{
				title: 'Unidade',
				href: '/simulacao-unidade',
				icon: <IoAirplane className={sizeIcons} />,
				submenu: false,
			},
		],
	},
];

export const SidebarDataAdmin = [
	{
		_categoryId: 1,
		category: 'Cadastro',
		items: [
			{
				title: 'Usuário',
				href: '/admin/usuario/1',
				icon: <TbUserPlus className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Grupo',
				href: '/admin/grupo/1',
				icon: <TbCircleLetterG className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Empresa',
				href: '/admin/empresa/1',
				icon: <TbCircleLetterE className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Unidade',
				href: '/admin/unidade/1',
				icon: <TbCircleLetterU className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Regime',
				href: '/admin/regime/1',
				icon: <TbCircleLetterR className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Chiller',
				href: '/admin/chiller/1',
				icon: <TbCircleLetterC className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Condensação',
				href: '/admin/condensacao/1',
				icon: <TbCircleLetterC className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Máquina de Gelo',
				href: '/admin/maquina-gelo/1',
				icon: <TbCircleLetterM className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Sistema de água gelada',
				href: '/admin/sistema-agua-gelada/1',
				icon: <TbCircleLetterS className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Ambiente',
				href: '/admin/ambiente/1',
				icon: <TbCircleLetterA className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Evaporador',
				href: '/admin/evaporador/1',
				icon: <TbCircleLetterE className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Marca',
				href: '/admin/marca/1',
				icon: <TbCircleLetterM className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Modelo',
				href: '/admin/modelo/1',
				icon: <TbCircleLetterM className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Condensador',
				href: '/admin/condensador/1',
				icon: <TbCircleLetterC className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Bomba',
				href: '/admin/bomba/1',
				icon: <TbCircleLetterB className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Ventilador',
				href: '/admin/ventilador/1',
				icon: <TbCircleLetterV className={sizeIcons} />,
				submenu: false,
			},
		],
	},
	{
		_categoryId: 2,
		category: 'Compressores',
		items: [
			{
				title: 'Compressor',
				href: '/admin/compressor/1',
				icon: <TbCircleLetterC className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Modelo Compressor',
				href: '/admin/modeloCompressor/1',
				icon: <TbCircleLetterM className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Tipo Operação Compressor',
				href: '/admin/tipoOperacaoCompressor/1',
				icon: <TbCircleLetterT className={sizeIcons} />,
				submenu: false,
			},
			{
				title: 'Voltar',
				href: '/escolha-unidade',
				icon: <ArrowLeft className={sizeIcons} />,
				submenu: false,
			},
		],
	},
];

export const tipoOperacaoCompressor = [
	{
		id: 1,
		nome: 'BOOSTER',
	},
	{
		id: 2,
		nome: 'COMPRESSAO_UNICA',
	},
	{
		id: 3,
		nome: 'ALTA',
	},
];

export const delayTime = 2000;
