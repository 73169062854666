import { useEffect, useState } from "react";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiRelatorio from "../../services/apis/apiRelatorio";
import CardLayout from "../../components/common/CardLayout";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import NoDataAlert from "../../components/common/returns/NoDataAlert";
import { DatePicker } from "../../components/ui/datepicker";
import { Button } from "../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import DefaultChartRegime from "../../components/relatorio/DefaultChartRegime";
import { getClientTimeZoneOffset } from "../../funcs";

export default function Regime() {
    const UNIDADE = getCurrentUnidadeId();
    const [dados, setDados] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tempSelectedDate, setTempSelectedDate] = useState(new Date());
    const [tempPeriodo, setTempPeriodo] = useState('dia');
    const [periodo, setPeriodo] = useState('dia');

    const handleDateChange = (date) => {
        setTempSelectedDate(date);
    };

    const handleSearch = async () => {
        setSelectedDate(tempSelectedDate);
        setPeriodo(tempPeriodo);
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataFormatada = selectedDate?.toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });

            const timeZoneOffset = getClientTimeZoneOffset();

            const fusoData = {
                data: dataFormatada,
                TimeZoneNumber: timeZoneOffset
            }
            const res = await apiRelatorio.relatorioRegime(UNIDADE, fusoData, periodo)
            setDados(res.data);
        }
        fetchData();
    }, [selectedDate, UNIDADE, periodo]);

    return (
        <section className="flex flex-col pb-16">
            <div className="flex items-center justify-center">
                <div className="flex justify-center items-center w-full p-4 gap-2">
                    <DatePicker
                        selectedDate={tempSelectedDate}
                        onDateChange={handleDateChange}
                    />
                    <div className="w-[100px]">
                        <Select
                            defaultValue={tempPeriodo}
                            onValueChange={(value) => setTempPeriodo(value)}
                        >
                            <SelectTrigger className="w-full text-fontColor bg-opacity-70 hover:bg-opacity-100">
                                <SelectValue placeholder="Período" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem
                                    value="dia"
                                >
                                    Dia
                                </SelectItem>
                                <SelectItem

                                    value="semana"
                                >
                                    Semana
                                </SelectItem>
                                {/* <SelectItem
                                    value="mes"
                                >
                                    Mês
                                </SelectItem> */}
                            </SelectContent>
                        </Select>
                    </div>
                    <Button onClick={handleSearch}>
                        Buscar
                    </Button>
                </div>
                <div className="inline-flex w-32 justify-end px-2">
                    <p className="text-sm text-secondary drop-shadow-1xl">
                        {selectedDate
                            ? selectedDate.toLocaleDateString(
                                'pt-BR',
                                {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }
                            )
                            : ''}
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-4 py-2">
                {

                    dados.map((regime) => (
                        <CardLayout key={regime.id}>
                            <Accordion
                                type="single"
                                defaultValue={`item-${regime.id}`}
                                collapsible={true}
                            >
                                <AccordionItem
                                    value={`item-${regime.id}`}
                                >
                                    <div className="font-semibold">
                                        <AccordionTrigger>
                                            <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
                                                {regime &&
                                                    <div
                                                        className={`uppercase`}
                                                    >
                                                        <p>
                                                            <span className="capitalize">
                                                                {
                                                                    regime.nome
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </AccordionTrigger>
                                    </div>
                                    {
                                        regime.leiturasRegimes.length === 0 ?
                                            <NoDataAlert /> :
                                            <AccordionContent>
                                                <div className="flex flex-col gap-4 px-4 items-center justify-center">
                                                    {
                                                        regime.leiturasRegimes.length === 0 ?
                                                            <div className="flex flex-col gap-4 justify-center w-full items-center" key={regime.id}>
                                                                <div className="w-[300px]">
                                                                    <NoDataAlert />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="flex flex-col lg:flex-row justify-center items-center w-full px-2" key={regime.id}>
                                                                <div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
                                                                    <DefaultChartRegime chartDataProps={regime} chartType={periodo} />
                                                                </div>

                                                            </div>
                                                    }
                                                </div>
                                            </AccordionContent>
                                    }
                                </AccordionItem>
                            </Accordion>
                        </CardLayout>
                    ))
                }
            </div>
        </section>
    );
}